import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/header';
import InfoList from '../components/infoList';

const Views = ({ pageContext, data, location }) => {
  const { intro } = pageContext;
  return (
    <article className="page views">
      <Header title="Vistas" back="/" intro={intro} path={location.pathname} />

      <InfoList
        showIntro={true}
        info={data.info.edges}
        className="with-description"
      />
    </article>
  );
};

export default Views;

export const query = graphql`
  query {
    info: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/vistas/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            intro
          }
        }
      }
    }
  }
`;
